<template>
	<div class="client-analytics position-relative w-100 h-100 d-flex flex-column">
		<div class="controls position-relative w-100 p-4 d-flex justify-content-between">
			<div class="date-pickers position-relative h-100 d-flex align-items-center">
				<b-form-datepicker class="date-picker" placeholder="From"
				                   v-bind:class="{'invalid': beginInvalid}"
				                   v-bind:value="begin"
				                   v-on:input="updateDate('begin', $event)">
				</b-form-datepicker>
				<font-awesome-icon class="range-icon position-relative mx-3"
				                   v-bind:icon="['fas', 'arrow-right']">
				</font-awesome-icon>
				<b-form-datepicker class="date-picker date-picker-overwrite" placeholder="From"
				                   v-bind:class="{'invalid': endInvalid}"
				                   v-bind:value="end"
				                   v-on:input="updateDate('end', $event)">
				</b-form-datepicker>
			</div>
			<div class="control-buttons position-relative ml-3 d-flex">
				<button type="button" class="button btn btn-custom-light w-auto d-flex align-items-center overflow-hidden"
				        v-on:click="exportCSV">
					<font-awesome-icon class="icon mr-2" v-bind:icon="['fas', 'file-csv']"></font-awesome-icon>
					<span class="name font-weight-bold text-truncate">Export CSV</span>
				</button>
			</div>
		</div>
		<pie-chart v-bind:chart-data="chartData"
		           v-bind:data-changed="dataChanged"
		           v-on:pie-clicked="navigate($event)"
		           v-bind:style="{height: '75vh', padding: '0 16px 0 16px'}"></pie-chart>
	</div>
</template>

<script>
import format from "date-fns/format";
import FileSaver from "file-saver";
import { toCsv } from "@/worker/file-export.worker";
import { BFormDatepicker } from "bootstrap-vue";
import PieChart from "@/components/functional/chart/PieChart";

export default {
	name: "RevenuePie",
	
	components: {
		BFormDatepicker,
		PieChart,
	},
	
	created() {
		this.generateData(Date.now() - 2.592e+9, Date.now());
	},
	
	data() {
		return {
			// being init to 30 days ago
			begin: format(new Date(Date.now() - 2.592e+9), "yyyy-MM-dd"),
			end: format(new Date(), "yyyy-MM-dd"),
			beginInvalid: false,
			endInvalid: false,
			dataChanged: false,
			/*colors: ["#611fa7", "#ff577f", "#007ed6", "#4aac4a", "#ff6b45", "#E9B0DF",
				 "#ffa600",
				"#bace94",
				"#e3b20f",  "#ff6961",
				 "#47B39C", ],*/
			ids: [],
			chartData: {
				labels: [],
				datasets: [
					{
						data: [],
						// "#" + Math.floor(Math.random()*16777215).toString(16);
						backgroundColor: [],
						borderColor: "transparent",
					},
				],
			}
		}
	},
	
	methods: {
		generateData(begin, end) {
			let colorIndex = 0;
			const ids = [];
			const labels = [];
			const data = [];
			this.$store.state.contents.clients.forEach(({ _id, name }) => {
				const revenue = this.$store.state.contents.salesOrders.reduce((acc, { client, totalAmount, issuedAt }) => {
					const issuedAtTS = issuedAt ? new Date(issuedAt).getTime() : 0;
					if (_id === client && issuedAtTS >= begin && issuedAtTS <= end) return acc + parseFloat(totalAmount);
					return acc;
				}, 0);
				ids.push(_id);
				labels.push(name);
				data.push(revenue);
				if (revenue > 0) {
					this.chartData.datasets[0].backgroundColor.push("#" + Math.floor(Math.random()*16777215).toString(16));
				} else {
					this.chartData.datasets[0].backgroundColor.push("#707070");
				}
			});
			this.ids = ids;
			this.chartData.labels = labels;
			this.chartData.datasets[0].data = data;
		},
		
		updateDate(type, dateString) {
			this[type] = dateString;
			const beginTimeStamp = new Date(`${this.begin}T23:59:59`).getTime();
			const endTimeStamp = new Date(`${this.end}T23:59:59`).getTime();
			if (beginTimeStamp > endTimeStamp) {
				this.beginInvalid = true;
				this.endInvalid = true;
			} else {
				this.beginInvalid = false;
				this.endInvalid = false;
				this.generateData(beginTimeStamp, endTimeStamp);
				this.dataChanged = !this.dataChanged;
			}
		},
		
		async exportCSV() {
			const csvData = this.chartData.labels.map((label, index) => {
				const sales = this.chartData.datasets[0].data[index].toFixed(2);
				return {
					client: label,
					sales: `$${sales}`
				}
			});
			const file = await toCsv(csvData);
			FileSaver.saveAs(file, `ClientAnalytics.csv`);
		},
		
		async navigate(index) {
			await this.$router.push(`/client/${this.ids[index]}`);
		}
	}
}
</script>

<style lang="scss" scoped>
.control-buttons {
	min-width: 40px;
}

.date-picker {
	// overwrite picker label
	:last-child {
		white-space: nowrap !important;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
	}
	
	&.invalid {
		box-shadow: $shadow-input-invalid !important;
	}
}

.range-icon {
	width: 20px;
	height: 20px;
}
</style>
