<script>
import { Pie } from 'vue-chartjs';

export default {
	name: "PieChart",
	
	extends: Pie,
	
	props: {
		chartData: {
			type: Object,
			required: true,
		},
		dataChanged: {
			type: Boolean,
			required: true
		}
	},
	
	mounted () {
		Chart.Legend.prototype.afterFit = function() {
			this.height = this.height + 30;
		};
		this.renderChart(this.chartData, this.options)
	},
	
	data() {
		return {
			options: {
				responsive: true,
				maintainAspectRatio: false,
				//spanGaps: true,
				onClick: this.clickHandler,
				cutoutPercentage: 0,
				animation: {
					animateScale: true
				},
				legend: {
					position: "left",
					labels: {
						padding: 15,
            fontSize: 16,
					}
				},
				tooltips: {
					enabled: true,
					callbacks: {
						label: function(tooltipItem, data) {
							return `${data.labels[tooltipItem.index]}: $${data.datasets[0].data[tooltipItem.index].toFixed(2)} HKD`;
						}
					}
				},
			}
		}
	},
	
	watch: {
		dataChanged() {
			this.renderChart(this.chartData, this.options);
		}
	},
	
	methods: {
		clickHandler(points, event) {
			if (event.length > 0) this.$emit("pie-clicked", event[0]._index);
		}
	}
}
</script>

<style scoped>

</style>